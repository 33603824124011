import React from "react";
import styled from "styled-components";
import {
  IoPhonePortrait,
  IoHome,
  IoHappy,
  IoFlash,
  IoDocuments,
  IoCheckmark,
  IoCloudDone,
  IoOpenOutline,
  IoCheckboxOutline,
  IoPaperPlane,
  IoShieldCheckmark,
  IoTimer,
  IoPeople,
  IoHeart,
  IoLocation,
  IoSunny,
  IoSend,
  IoSync,
} from "react-icons/io5";
import { Link } from "gatsby";
import { useModal } from "../components/useModal";

import Image from "../components/image";
import {
  Feature,
  Modal,
  ImageGraphic,
  LogoScroller,
  CustomerQuote,
  CallToAction,
} from "../components/site";
import {
  Button,
  Container,
  Wrapper,
  Row,
  Box,
  FlexButtons,
} from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";

import PayHeroLogo from "../images/logos/PayHero/PayHero_Full_White.svg";

// const CustomerName = styled.h4`
//   color: #fff;
//   position: absolute;
//   bottom: 60px;
//   left: 60px;
// `;

const DataphyllContainer = styled(Container)`
  background-image: linear-gradient(to right, #297c3c, #a2ca45);

  .dataphylllogo {
    display: block;
    width: 150px;
  }
`;

const Horticulture = (props) => {
  const [itemModalOpen, setItemModalOpen, toggleModal] = useModal();

  const industry_benefits = [
    "Accurately pay variable hour employees",
    "Stay compliant with automatic minimum wage top ups",
    "Easily manage accommodation allowances & piecemeal rates",
    "Track to-the-minute timesheets & breaks",
    "Compliant leave calculations for variable hour employees",
    "Integrate with orchard management software",
  ];

  const dataphyll_benefits = [
    "Incentivise workers by paying them based on performance",
    "Capture accurate quantity and quality information",
    "Meet your GAP requirements",
    "Track timesheets, breaks and pick weights",
    "Calculate accurate wages, send payslips and automatically file with IRD",
  ];

  return (
    <Layout>
      <Seo
        title="Payroll Software for Horticulture & Viticulture"
        description="Focus on scaling your business. Let payroll take care of itself. Stress-free rosters, attendance, timesheets & payroll for Kiwi horticulture & viticulture. Get started today."
        pathname={props.location.pathname}
      />
      <Container>
        <Wrapper stackGap={70} noPaddingBottom>
          <Row stackGap={80} alignCenter>
            <Box size={50} stackGap={50} centerOnMobile>
              <Box stackGap={20}>
                <h1 className="-lg -primary badge">
                  Payroll for Horticulture & Viticulture
                </h1>
                <h2 className="h1">
                  Payroll and Workforce Management for Growers
                </h2>
                <Box stackGap={15}>
                  {industry_benefits.map((item, i) => {
                    return (
                      <Row
                        alignCenter
                        noBreak
                        stackGap={10}
                        justify="flex-start"
                        key={i}
                        css={{ fontSize: "1.2rem", textAlign: "left" }}
                        centerOnMobile
                      >
                        <IoCheckmark
                          css={{
                            fontSize: "1.8rem",
                            color: "#5eb22e",
                            flex: "0 0 auto",
                          }}
                        />
                        <span>{item}</span>
                      </Row>
                    );
                  })}
                </Box>
              </Box>
              <Box stackGap={15}>
                <FlexButtons>
                  <Button className="primary -lg gtm-cta" to="/signup">
                    Try 14 Days Free
                  </Button>
                  <Button
                    className="grey -lg gtm-cta"
                    onClick={toggleModal}
                    atag
                  >
                    Book a Sales Call
                  </Button>
                </FlexButtons>
                <p css={{ color: "#999" }}>
                  Get started in minutes, no credit card required.
                </p>
              </Box>
            </Box>
            <Box size={50} css={{ padding: "40px" }}>
              <ImageGraphic variant="1" />
              <Image
                filename="Horticulture_Hero.jpg"
                alt="Payroll for Horticulture"
                centerImage
                addShadow
                rounded
              />
              {/* <CustomerName>
                <b className="-handWritten">OOB Organic,</b>
                <br /> <span css={{ fontSize: "1rem" }}>PayHero Customer</span>
              </CustomerName> */}
            </Box>
          </Row>
          <LogoScroller>
            <Image
              filename="Horticulture_Customer_Logos.png"
              alt="PayHero Horticulture Payroll Customers"
              centerImage
            />
          </LogoScroller>
          <hr />
        </Wrapper>
      </Container>
      <Container>
        <Wrapper stackGap={150}>
          <Row stackGap={100} mobileReverse alignCenter>
            <Box size={50}>
              <ImageGraphic variant="2" />
              <Image
                alt="Sync timesheets from mobile directly to payroll"
                filename="PayHero_Review_Horticulture.png"
                addShadow
                centerImage
                rounded
              />
            </Box>
            <Box size={50} stackGap={40} centerOnMobile>
              <Box stackGap={10}>
                <h2 className="h4 -fontDark -fontNormal">
                  Stress-free payroll
                </h2>
                <h4 className="h2">
                  Pay employees right with payroll software, built for growing
                  businesses
                </h4>
              </Box>
              <Feature
                icon={<IoHome key={0} />}
                title="Accommodation and piecemeal"
                description="It’s straightforward to set up and manage horticulture specific payroll needs in PayHero, including paying piecemeal rates and providing accommodation allowances."
              />
              <Feature
                icon={<IoCheckboxOutline key={0} />}
                title="Minimum wage top up"
                description="PayHero ensures that workers earning piecemeal rates, and salaried staff who work variable hours, are always paid at least the minimum wage, so you can be sure you’re compliant and your employees are paid fairly."
              />
              <Feature
                icon={<IoPaperPlane key={0} />}
                title="Integrate with Xero"
                description="Automatically send payroll data through to Xero. Keep it simple or assign wage costs to different account codes and tracking categories for a more detailed breakdown."
              />
            </Box>
          </Row>
          <CustomerQuote
            quote="The only software on the market currently able to accurately calculate the leave entitlements for employees with irregular working patterns, without manual intervention, is PayHero."
            name="Irene Bennetts"
            company="Admin Army"
            pic="Irene_Circle.png"
            large
            center
          />
          <Row stackGap={100} alignCenter>
            <Box size={50} stackGap={40} centerOnMobile>
              <Box stackGap={10}>
                <h2 className="h4 -fontDark -fontNormal">
                  Simplify compliance
                </h2>
                <h4 className="h2">
                  Look after your business and let PayHero take care of the
                  tricky payroll stuff
                </h4>
              </Box>
              <Feature
                icon={<IoShieldCheckmark key={0} />}
                title="Stay onside with the Holidays Act"
                description="Horticulture is a hotbed of potentially tricky payroll scenarios with part time and variable hour staff and regularly changing work patterns. PayHero follows the latest MBIE Holidays Act guidance to ensure your employees maintain the correct holiday entitlements, even when things change."
              />
              <Feature
                icon={<IoCloudDone key={0} />}
                title="Cloud-based & always up-to-date"
                description="Pay your wages from any computer or tablet with an internet connection. Every time payroll and labour laws change we update PayHero to keep you compliant. No downloads or time consuming updates required – just business as usual."
              />
              <Feature
                icon={<IoSync key={0} />}
                title="Automatically extend leave anniversaries for seasonal staff"
                description="Employees receive annual leave and sick leave entitlements for each year of employment, but what happens if they don’t work for you year-round? PayHero can automatically defer annual leave and sick leave entitlement dates for employees that have gaps between work assignments."
              />
              <Feature
                icon={<IoSend key={0} />}
                title="Automated payday filing"
                description={[
                  <Link to="/payday-filing">Payday filing</Link>,
                  <span>
                    {" "}
                    is a piece of cake in PayHero – just connect your account to
                    IRD and we’ll automatically send your payroll information
                    through after every pay.
                  </span>,
                ]}
              />
            </Box>
            <Box size={50}>
              <Image
                filename="PayrollForHorticulture.jpg"
                alt="Payroll software compliance for Horticulture"
                centerImage
                rounded
              />
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <DataphyllContainer className="-whiteOut">
        <Wrapper stackGap={80}>
          <Row stackGap={70} alignCenter mobileReverse>
            <Box size={50}>
              <Image
                alt="Dataphyll + PayHero"
                filename="Dataphyll_Mobile.png"
                addShadow
                centerImage
                rounded
              />
            </Box>
            <Box size={50} stackGap={40} centerOnMobile>
              <Box stackGap={30}>
                {/* <h2 className="h4 -fontNormal">Dataphyll + PayHero</h2> */}
                <Row alignCenter stackGap={15} justify="flex-start">
                  <div className="dataphylllogo">
                    <Image filename="Dataphyll_White.png" alt="Dataphyll" />
                  </div>
                  <span>+</span>
                  <img src={PayHeroLogo} alt="PayHero" height="28" />
                </Row>
                <h2>Eliminate orchard paperwork and pay employees right</h2>
              </Box>
              <Box stackGap={15}>
                {dataphyll_benefits.map((item, i) => {
                  return (
                    <Row
                      alignCenter
                      noBreak
                      stackGap={10}
                      justify="flex-start"
                      key={i}
                      css={{ fontSize: "1.2rem", textAlign: "left" }}
                      centerOnMobile
                    >
                      <IoCheckmark
                        css={{
                          fontSize: "1.8rem",
                          color: "#5eb22e",
                          flex: "0 0 auto",
                        }}
                      />
                      <span>{item}</span>
                    </Row>
                  );
                })}
              </Box>
              <FlexButtons stackGap={30}>
                <Button
                  className="dark -lg -centerOnMobile gtm-cta"
                  to="/blog/dataphyll-payhero-integration"
                >
                  Find Out More
                </Button>
                <a
                  href="https://www.dataphyll.com/"
                  className="link-floating"
                  target="_blank"
                >
                  Visit Website{" "}
                  <IoOpenOutline css={{ top: "1px", marginLeft: "5px" }} />
                </a>
              </FlexButtons>
            </Box>
          </Row>
        </Wrapper>
      </DataphyllContainer>
      <Container>
        <Wrapper>
          <Row stackGap={100} alignCenter>
            <Box size={50} stackGap={40} centerOnMobile>
              <Box stackGap={10}>
                <h2 className="h4 -fontDark -fontNormal">
                  Employee management tools
                </h2>
                <h4 className="h2">
                  Handle timesheets your way, and keep track of key employment
                  documents
                </h4>
              </Box>
              <Feature
                icon={<IoLocation key={0} />}
                title="Supervisor clock ins"
                description="If you’ve got teams of people working at different locations your supervisors can clock employees in & out of work and breaks from their phone. Only your supervisors need to use the technology, while your pickers get on with the job."
              />
              <Feature
                icon={<IoPhonePortrait key={0} />}
                title="Timesheets in their pocket"
                description="Or, get your employees to submit their own timesheets against different jobs, products or locations by entering start, end and break times or using the GPS start/stop timer. They can access their old payslips too, so you don’t need to dig them out."
              />
              <Feature
                icon={<IoDocuments key={0} />}
                title="HR Docs"
                description="Keep track of RSE workers’ employment-related paperwork by saving key documents like contracts, permits, licences and IRD forms on the employee record."
              />
            </Box>
            <Box size={50}>
              <ImageGraphic variant="2" />
              <Image
                alt="Timesheets, leave, expenses & payslips on mobile"
                filename="PayHero_Mobile_Horticulture.png"
                maxWidth={500}
                addShadow
                centerImage
                rounded
              />
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <Container bg="lightGrey">
        <Wrapper>
          <Box stackGap={5} className="-textCenter">
            <h2>
              More reasons why horticulture & viticulture choose PayHero for
              payroll
            </h2>
            <h4>
              Timesheets, minimum wage top up & payroll in one simple online
              tool.
            </h4>
          </Box>
          <Box stackGap={40}>
            <Row stackGap={40} className="-textCenter">
              <Box size={33}>
                <h3>
                  <IoTimer />
                  <br />
                  Pay your staff in minutes
                </h3>
                <p>
                  Time entries, leave & expenses submitted by your employees
                  from their mobile app show up instantly & automatically in
                  PayHero, ready for processing payroll.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoPeople />
                  <br />
                  Workforce management
                </h3>
                <p>
                  Add employee scheduling or contractor management from our
                  wider suite of workforce management software to better manage
                  your on-demand workers.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoFlash />
                  <br />
                  Automated public holiday calculations
                </h3>
                <p>
                  PayHero determines whether a public holiday is an otherwise
                  working day for an employee based on their recent work pattern
                  and calculates their entitlements accordingly.
                </p>
              </Box>
            </Row>
            <Row stackGap={40} className="-textCenter">
              <Box size={33}>
                <h3>
                  <IoSunny />
                  <br />
                  Easy leave requests
                </h3>
                <p>
                  Employees can submit leave requests, check the approval status
                  and balances and see a record of leave already taken and
                  booked in with a simple calendar view.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoHappy />
                  <br />
                  Support you can rely on
                </h3>
                <p>
                  Our friendly Wellington-based support team is available by
                  phone or email to help you out whenever you need it.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoHeart />
                  <br />
                  Try before you buy
                </h3>
                <p>
                  With a fully featured{" "}
                  <Link to="/signup">free 14 day trial</Link>, you can take
                  PayHero for a risk-free test run before committing.
                </p>
              </Box>
            </Row>
          </Box>
        </Wrapper>
      </Container>
      <CallToAction showSalesButton />
      <Modal
        isActive={itemModalOpen}
        embedURL="https://calendly.com/d/ynv-22p-2r5"
        embedTitle="Book a Sales Call | PayHero Sales"
        handleClose={() => setItemModalOpen(false)}
      />
    </Layout>
  );
};

export default Horticulture;
